import React, { useState, useEffect, useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  makeStyles,
  Box,
  Divider,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { navigateToUrl } from 'single-spa';
import { Auth } from 'aws-amplify';
import {
  SideNav,
  FdIconsV5,
  AuthContext,
  FdProgress,
  globalStore,
  useSnapshot,
  FdTypography,
  useLocalStorage,
  Authorization,
  setTheme,
} from '@fifthdomain/fe-shared';
import { useLocation } from 'react-router-dom';
import {
  getUserById,
  getUserAssessment,
  listProductsByOrgId,
  listDashboardsByOrgId,
  getSystemTime,
} from '../graphql/queries';
import { getCompetitionStatus } from '../shared/utils/getCompetitionStatus';
import MenuItems from '../components/MenuItems';
import StyledSelect from '../components/StyledSelect';

const { Assignment, Dashboard, EmojiEvents, Score, Campaign, Chat } = FdIconsV5;

const useStyles = makeStyles(() => ({
  loading: {
    position: 'fixed',
    top: '50%',
    left: '10%',
  },
}));

const isUrlMatchingPattern = (url, regexArray) => {
  return regexArray.reduce((result, regex) => result || regex.test(url), false);
};

const getSelectedNavItems = (url, _drawerFixed) => {
  switch (true) {
    case isUrlMatchingPattern(url, [/\/labs\/courses/, /\/assessor/]):
      return {
        selected: 'Home',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/assessor\/assessment-tasks\//,
      /\/competitions\/competition-tasks\//,
    ]):
      return {
        selected: 'Challenges',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/assessor\/assessment-overview\//,
      /\/competitions\/competition-overview\//,
    ]):
      return {
        selected: 'Overview',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/competitions\/competition-scoreboard\//,
    ]):
      return {
        selected: 'Scoreboard',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/competitions\/competition-announcements\//,
    ]):
      return {
        selected: 'Announcements',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/competitions\/competition-chat-forum\//,
    ]):
      return {
        selected: 'Chat Forum',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/assessor\/assessment-complete\/?$/,
      /\/assessor\/survey\/?$/,
    ]):
      return { defaultOpen: false };
    case isUrlMatchingPattern(url, [/\/labs\/?$/]):
      return {
        selected: 'Labs',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/labs\/vms/]):
      return {
        selected: 'VMs',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/labs\/quizzes/]):
      return {
        selected: 'Quizzes',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/tasks\/?$/,
      /\/tasks\/create\//,
      /\/tasks\/edit-draft\//,
    ]):
      return {
        selected: 'Challenges',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/user-management\/affiliated-users/,
      /\/competitions\/affiliated-view-user/,
      /\/competitions\/training\/create/,
      /\/competitions\/training\/edit/,
      /^\/competitions\/trophy-room\/.+$/,
    ]):
      return {
        selected: MenuItems.affiliated.name,
        selectedParent: MenuItems.users.name,
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/user-management\/squads/]):
      return {
        selected: MenuItems.squads.name,
        selectedParent: MenuItems.users.name,
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/user-management\/non-affiliated-users/,
      /\/competitions\/non-affiliated-view-user/,
    ]):
      return {
        selected: MenuItems.nonAffiliated.name,
        selectedParent: MenuItems.users.name,
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/user-management\/tags/]):
      return {
        selected: MenuItems.tagDirectory.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/landing\/?$/,
      /\/landing\/landing-homepage\/?$/,
      /\/landing\/org\/profile\/?$/,
      /\/landing\/user\/profile\/?$/,
    ]):
      return {
        selected: 'Home',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/org\/template-library/]):
      return {
        selected: MenuItems.templateLibrary.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/competitions\/skills-overview/]):
      return {
        selected: MenuItems.mySkills.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/landing\/landing-homepage\/my-events/]):
      return {
        selected: MenuItems.myEvents.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/landing\/teams/]):
      return {
        selected: MenuItems.myTeams.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/landing\/hall-of-fame/]):
      return {
        selected: MenuItems.hallOfFame.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/^\/competitions\/trophy-room\/?$/]):
      return {
        selected: MenuItems.trophyRoom.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    default:
      return { defaultOpen: false };
  }
};

const Sidebar = () => {
  const [userAssessId, setUserAssessId] = useState(undefined);
  const [competitionStatus, setCompetitionStatus] = useState(undefined);
  const [guidedCompetition, setGuidedCompeition] = useState(false);
  const [roleMode, setRoleMode] = useState('Manage');
  const [drawerFixed, setDrawerFixed] = useState(false);
  const pathName = useLocation().pathname;
  const globalSnap = useSnapshot(globalStore);
  const [userPreferencesSidebar, setUserPreferencesSidebar] = useLocalStorage(
    'user-preferences-sidebar-user-mode',
    {
      defaultValue: {
        [globalSnap?.userId]: {
          mode: 'Manage',
        },
      },
    },
  );

  useEffect(() => {
    setRoleMode(userPreferencesSidebar?.[globalSnap?.userId]?.mode ?? 'Manage');
  }, [globalSnap.userId]);

  const baseMenu = {
    navigationItems: [],
    handleOnSelectedItem: (__, path) => {
      navigateToUrl(path);
    },
    mainPageIds: [
      'topnav',
      'assessor',
      'user-management',
      'courses',
      'tasks',
      'competitions',
      'labs-fe',
      'landing',
      'organisation',
    ],
    ...getSelectedNavItems(pathName, drawerFixed),
  };
  const { userType, orgPricingTier, isAffiliated, permissions } = globalSnap;

  const hasCustomOrgPricingTier =
    orgPricingTier !== undefined &&
    !['STARTER', 'ADVANCED'].includes(orgPricingTier);

  const isParticipant = userType === 'PARTICIPANT';
  const isManageMode = roleMode === 'Manage';

  const classes = useStyles();
  const [menuItems, setMenuItems] = useState(baseMenu);
  const { user, setUser } = useContext(AuthContext);

  const hasManagerialPermission = isAffiliated
    ? Authorization.hasManagerialPermissions(permissions)
    : false;

  // user with create permission
  const canManageContent = Authorization.canManageContent(permissions);

  // user with review content
  const canReviewContent = Authorization.canReviewContent(permissions);

  const canViewDashboards = Authorization.canViewDashboards(
    permissions,
    orgPricingTier,
  );

  // user with manage-tags permission
  const canManageTags = Authorization.canManageTags(
    permissions,
    orgPricingTier,
  );

  const canManageSquads = Authorization.canManageSquads(permissions);

  const canViewTemplateLibrary =
    Authorization.canViewTemplateLibrary(permissions);

  const canManageUsers = Authorization.canManageUsers(permissions);
  const canViewInsights = Authorization.canViewInsights(permissions);

  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    skip: !user,
    fetchPolicy: 'network-only',
  });

  const {
    data: serverTime,
    loading: serverTimeLoading,
    refetch: refetchServerTime,
  } = useQuery(gql(getSystemTime));

  const { data: orgProductsData, loading: orgProductsLoading } = useQuery(
    gql(listProductsByOrgId),
    {
      variables: {
        orgId: userData?.getUserById?.items?.[0].orgId,
      },
      skip: !userData,
    },
  );

  const { data: dashboardsData, loading: dashboardsLoading } = useQuery(
    gql(listDashboardsByOrgId),
    {
      variables: {
        orgId: userData?.getUserById?.items?.[0].orgId,
      },
      skip: !userData,
    },
  );

  const { loading: getUserAssessmentLoading, refetch } = useQuery(
    gql(getUserAssessment),
    {
      variables: {
        id: userAssessId,
      },
      skip: !userAssessId,
      onCompleted: (_data) => {
        const {
          assessment: { endDateTime, startDateTime, guided },
        } = _data?.getUserAssessment || { assessment: {} };
        const _status = getCompetitionStatus(
          startDateTime,
          endDateTime,
          serverTime?.getSystemTime,
        );
        setCompetitionStatus(_status);
        setGuidedCompeition(guided);
      },
    },
  );

  useEffect(() => {
    if (pathName.includes('homepage') || pathName.includes('labs/courses')) {
      refetchServerTime();
      refetch({ id: userAssessId });
    }
  }, [pathName, refetch, refetchServerTime, userAssessId]);

  // Build Competitions SideBar for Participants
  const sideNavCompParticipant = (_competitionStatus, _guidedCompetition) => {
    const navItems = [];
    if (isParticipant) {
      const userAssessmentId = pathName.split('/').pop();
      setUserAssessId(userAssessmentId);
      if (_competitionStatus !== 'ENDED') {
        navItems.push({
          name: 'Challenges',
          path: `/competitions/competition-tasks/${userAssessmentId}`,
          icon: <EmojiEvents />,
          dataCy: 'sidebar-competitions-tasks',
        });
        navItems.push({
          name: 'Overview',
          path: `/competitions/competition-overview/${userAssessmentId}`,
          icon: <Dashboard />,
          dataCy: 'sidebar-competitions-overview',
        });
        navItems.push({
          name: 'Announcements',
          path: `/competitions/competition-announcements/${userAssessmentId}`,
          icon: <Campaign />,
          dataCy: 'sidebar-competitions-announcements',
        });
        if (!_guidedCompetition) {
          navItems.push({
            name: 'Chat Forum',
            path: `/competitions/competition-chat-forum/${userAssessmentId}`,
            icon: <Chat />,
            dataCy: 'sidebar-competitions-chat-forum',
          });
        }
      }
      if (!_guidedCompetition) {
        navItems.push({
          name: 'Scoreboard',
          path: `/competitions/competition-scoreboard/${userAssessmentId}`,
          icon: <Score />,
          dataCy: 'sidebar-competitions-overview',
        });
      }
    }
    return navItems;
  };

  const sideNavTrainingParticipant = (_competitionStatus) => {
    const navItems = [];
    if (isParticipant) {
      const userAssessmentId = pathName.split('/').pop();
      setUserAssessId(userAssessmentId);
      if (_competitionStatus !== 'ENDED') {
        navItems.push({
          name: 'Challenges',
          path: `/competitions/training-tasks/${userAssessmentId}`,
          icon: <EmojiEvents />,
          dataCy: 'sidebar-training-tasks',
        });
        navItems.push({
          name: 'Overview',
          path: `/competitions/training-overview/${userAssessmentId}`,
          icon: <Dashboard />,
          dataCy: 'sidebar-training-overview',
        });
      }
    }
    return navItems;
  };

  // Build Assessor SideBar for Participants
  const sideNavAssessParticipant = () => {
    const navItems = [];
    if (isParticipant) {
      const userAssessmentId = pathName.split('/').pop();
      navItems.push({
        name: 'Challenges',
        path: `/assessor/assessment-tasks/${userAssessmentId}`,
        icon: <Assignment />,
        dataCy: 'sidebar-assessment-tasks',
      });
      navItems.push({
        name: 'Overview',
        path: `/assessor/assessment-overview/${userAssessmentId}`,
        icon: <Dashboard />,
        dataCy: 'sidebar-assessment-overview',
      });
    }
    return navItems;
  };

  const MenuHeader = () => (
    <Box className="my-2">
      <Divider />
      <Box className="flex flex-col my-3 ml-6 w-full">
        {isManageMode && (
          <Box className="mb-2">
            <FdTypography
              variant="body1"
              style={{
                width: '197px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              color="secondary"
            >
              {globalSnap.orgName}
            </FdTypography>
          </Box>
        )}
        <Box className="flex items-center gap-x-3">
          <InputLabel htmlFor="mode-select-label">
            <FdTypography variant="captiontext1" color="primary">
              Mode
            </FdTypography>
          </InputLabel>
          <Select
            labelId="mode-select-label"
            id="mode-select"
            value={roleMode}
            label=""
            onChange={(e) => {
              setRoleMode(e?.target?.value);
              setUserPreferencesSidebar({
                ...userPreferencesSidebar,
                [globalSnap?.userId]: {
                  mode: e?.target?.value,
                },
              });
              if (e?.target?.value === 'Manage') {
                globalStore.userType = 'ADMIN';
                setTheme('light');
                navigateToUrl(MenuItems.adminHome.path);
              } else {
                globalStore.userType = 'PARTICIPANT';

                setTheme(globalSnap.theme);
                navigateToUrl(MenuItems.participantHome.path);
              }
            }}
            input={<StyledSelect />}
          >
            <MenuItem value="Participate">Participate</MenuItem>
            <MenuItem value="Manage">Manage</MenuItem>
          </Select>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  const hallOfFame = {
    ...MenuItems.hallOfFame,
    caption: globalSnap.orgName, // show current org name
  };

  // Build SideBar for User
  const sideNav = () => {
    const navItems = [];
    if (!userData) {
      return navItems;
    }

    // Non Affiliated user
    if (!isAffiliated) {
      navItems.push(MenuItems.participantHome);
      navItems.push(MenuItems.myEvents);
      navItems.push(MenuItems.mySkills);
      navItems.push(MenuItems.myTeams);
      navItems.push(MenuItems.trophyRoom);
      return navItems;
    }

    // Affiliated user
    if (isAffiliated) {
      // no managerial permissions
      if (!hasManagerialPermission) {
        navItems.push(MenuItems.participantHome);
        navItems.push(MenuItems.myEvents);
        navItems.push(MenuItems.mySkills);
        navItems.push(MenuItems.myTeams);
        navItems.push(MenuItems.trophyRoom);
        navItems.push(hallOfFame);
        return navItems;
      }

      // manager or admin permissions
      if (hasManagerialPermission) {
        // participate mode is selected
        if (!isManageMode) {
          navItems.push(MenuItems.participantHome);
          navItems.push(MenuItems.myEvents);
          navItems.push(MenuItems.mySkills);
          navItems.push(MenuItems.myTeams);
          navItems.push(MenuItems.trophyRoom);
          navItems.push(hallOfFame);
          return navItems;
        }

        // manage mode is selected
        if (isManageMode) {
          navItems.push(MenuItems.adminHome);
          // manage content
          if (hasCustomOrgPricingTier) {
            const contentChildren = canManageContent
              ? [
                  MenuItems.labs,
                  MenuItems.vms,
                  MenuItems.quizzes,
                  MenuItems.challenges,
                ]
              : // user with review content
              canReviewContent
              ? [MenuItems.challenges]
              : [];
            navItems.push({
              ...MenuItems.content,
              children: contentChildren,
            });
          }
          // manage users
          if (canManageUsers || canManageSquads) {
            navItems.push({
              ...MenuItems.users,
              children: [
                ...(canManageUsers
                  ? [MenuItems.affiliated, MenuItems.nonAffiliated]
                  : []),
                ...(canManageUsers || canViewInsights || canManageSquads
                  ? [MenuItems.squads]
                  : []),
              ],
            });
          }
          // template library
          if (canViewTemplateLibrary) {
            navItems.push(MenuItems.templateLibrary);
          }
          // manage tags
          if (canManageTags) {
            navItems.push(MenuItems.tagDirectory);
          }
          // hall of fame
          navItems.push(hallOfFame);
          // dashboards
          if (
            dashboardsData?.listDashboardsByOrgId?.items?.length > 0 &&
            canViewDashboards
          ) {
            navItems.push(MenuItems.dashboards);
          }
          return navItems;
        }
      }
    }
    return navItems;
  };

  const getNavItems = (url, _competitionStatus, _guidedCompetition) => {
    let navItems = [];
    switch (true) {
      case /\/assessor\/?$/.test(url):
      case /\/assessor\/users\/?$/.test(url):
      case /\/assessor\/create\/?$/.test(url):
      case /\/assessor\/view\//.test(url):
      case /\/assessor\/assessments-homepage\/?$/.test(url):
      case /\/assessor\/assessment-start\//.test(url):
      case /\/assessor\/dashboards(?:\/|$)/.test(url):
      case /\/assessor\/duplicate\//.test(url):
      case /\/user-management\/?$/.test(url):
      case /\/user-management\/affiliated-users\/?$/.test(url):
      case /\/user-management\/squads\/?$/.test(url):
      case /\/user-management\/squads\/create\/?$/.test(url):
      case /\/user-management\/squads\/view\//.test(url):
      case /\/user-management\/non-affiliated-users\/?$/.test(url):
      case /\/user-management\/tags\/?$/.test(url):
      case /\/user-management\/groups\/?$/.test(url):
      case /\/user-management\/groups\/create?$/.test(url):
      case /\/user-management\/groups\/edit\//.test(url):
      case /\/user-management\/quizzes\/?$/.test(url):
      case /\/user-management\/quizzes\/view\//.test(url):
      case /\/user-management\/quizzes\/create?$/.test(url):
      case /\/tasks\/view\//.test(url):
      case /\/tasks\/edit\//.test(url):
      case /\/tasks\/edit-draft\//.test(url):
      case /\/tasks\/review\//.test(url):
      case /\/tasks\/create\/?$/.test(url):
      case /\/tasks\/?$/.test(url):
      case /\/competitions\/competitions-homepage\/?$/.test(url):
      case /\/landing\/teams\/?$/.test(url):
      case /\/competitions\/skills-overview\/?$/.test(url):
      case /\/competitions\/view-team\//.test(url):
      case /\/competitions\/competition-start\//.test(url):
      case /\/competitions\/training-start\//.test(url):
      case /\/competitions\/view\//.test(url):
      case /\/competitions\/affiliated-view-user\//.test(url):
      case /\/competitions\/non-affiliated-view-user\//.test(url):
      case /\/competitions\/duplicate\//.test(url):
      case /\/competitions\/create\/?$/.test(url):
      case /\/competitions\/?$/.test(url):
      case /\/competitions\/users\/?$/.test(url):
      case /\/competitions\/training\/create\//.test(url):
      case /\/competitions\/training\/edit\//.test(url):
      case /\/competitions\/trophy-room\/?$/.test(url):
      case /\/competitions\/trophy-room\//.test(url):
      case /\/labs\/vms\/?$/.test(url):
      case /\/labs\/courses\/?$/.test(url):
      case /\/labs\/courses\/create\/?$/.test(url):
      case /\/labs\/courses\/view\//.test(url):
      case /\/labs\/courses\/view-admin\//.test(url):
      case /\/labs\/courses\/duplicate\//.test(url):
      case /\/labs\/quizzes\/?$/.test(url):
      case /\/labs\/quizzes\/create\/?$/.test(url):
      case /\/labs\/quizzes\/view\//.test(url):
      case /\/labs\/?$/.test(url): // for labs
      case /\/labs\/view\//.test(url):
      case /\/labs\/test-lab\//.test(url):
      case /\/labs\/view-course-lab\//.test(url):
      case /\/labs\/editBuild\//.test(url):
      case /\/labs\/editDraft\//.test(url):
      case /\/labs\/create\/?$/.test(url):
      case /\/landing\/learning\/?$/.test(url):
      case /\/landing\/capability\/?$/.test(url):
      case /\/landing\/?$/.test(url):
      case /\/landing\/org\/profile\/?$/.test(url):
      case /\/landing\/landing-homepage\/?$/.test(url):
      case /\/landing\/hall-of-fame\/?$/.test(url):
      case /\/org\/template-library\/?$/.test(url):
      case /\/org\/template-library\/create\/competition\/?$/.test(url):
      case /\/org\/template-library\/create\/assessment\/?$/.test(url):
      case /\/org\/template-library\/view\/assessment\//.test(url):
      case /\/org\/template-library\/view\/competition\//.test(url):
      case /\/assessor\/create\/template\/?$/.test(url):
      case /\/landing\/user\/profile\/?$/.test(url):
      case /\/landing\/landing-homepage\/my-events\/?$/.test(url):
        navItems = sideNav();
        break;
      case /\/competitions\/competition-tasks\//.test(url):
      case /\/competitions\/competition-scoreboard\//.test(url):
      case /\/competitions\/competition-announcements\//.test(url):
      case /\/competitions\/competition-chat-forum\//.test(url):
      case /\/competitions\/competition-overview\//.test(url):
        navItems = sideNavCompParticipant(
          _competitionStatus,
          _guidedCompetition,
        );
        break;
      case /\/competitions\/training-tasks\//.test(url):
      case /\/competitions\/training-overview\//.test(url):
        navItems = sideNavTrainingParticipant(_competitionStatus);
        break;
      case /\/assessor\/assessment-overview\//.test(url):
      case /\/assessor\/assessment-tasks\//.test(url):
        navItems = sideNavAssessParticipant();
        break;
      default:
        navItems = [];
    }
    return navItems;
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authData) => {
        setUser(authData);
      })
      .catch((err) => {
        console.log('error: ', err);
        return '';
      });
  }, []);

  useEffect(() => {
    const newNavItems = getNavItems(
      pathName,
      competitionStatus,
      guidedCompetition,
    );
    const newMenu = { ...menuItems };
    newMenu.navigationItems = newNavItems;
    setMenuItems({ ...newMenu, ...getSelectedNavItems(pathName, drawerFixed) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userData,
    pathName,
    drawerFixed,
    competitionStatus,
    orgProductsData,
    roleMode,
    userType,
    hasCustomOrgPricingTier,
  ]);

  if (
    userLoading ||
    orgProductsLoading ||
    dashboardsLoading ||
    getUserAssessmentLoading ||
    serverTimeLoading
  ) {
    return <FdProgress size="small" className={classes.loading} />;
  }

  if (menuItems?.navigationItems.length === 0) {
    return null; // hide sidebar if no entries
  }

  return (
    <SideNav
      {...menuItems}
      onDrawerOpenClose={() => setDrawerFixed(!drawerFixed)}
      MenuHeader={hasManagerialPermission ? MenuHeader : Divider}
    />
  );
};

export default Sidebar;
